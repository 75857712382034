<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "Blanklayout",
  title: process.env.VUE_APP_TITLE ? process.env.VUE_APP_TITLE : "Voltado",
  components: {},
};
</script>
